<!--
- bgText: text - not used
- backgroundImage: image - not used
-->

<template>
	<div class="cs-map-block cs-block" :class="styleClass">
		<div class="cs-block-base">
			<div class="container">
				<div class="row">
					<div class="col-lg-6">
						<CSMap :zoom="data.zoom" :center="coordinates" :marker="computedMarkers"
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						attribution:='&copy;
						<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>
						contributors' />
					</div>
					<div class="col-lg-6">
                        <div class="map-info" v-if="data.title || data.subTitle || data.text || data.buttons">
                            <h2 v-if="data.title" class="cs-title">
                                {{ data.title }}
                            </h2>
                            <h3 v-if="data.subTitle" class="cs-sub-title">
                                {{ data.subTitle }}
                            </h3>
                            <span v-if="data.text" class="cs-text" v-html="data.text"></span>

                            <div class="buttons" v-if="data.buttons && ((typeof data.buttons == 'object' && Object.keys(data.buttons).length) || (Array.isArray(data.buttons) && data.buttons.length))">
                                <Buttons :data="data.buttons" :cs_style="data.style" />
                            </div>
                        </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "MapBlock",
	components: {
		CSMap: () => import("../components/CSMap.vue"),
		Buttons: () => import("../components/CSUtilsButtons.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	computed: computed('MapBlock')
}
</script>

<style lang="scss" scoped>
@import "../styles/_mixins.scss";
.cs-block.cs-map-block {
	::v-deep {
		.leaflet-container {
			height: 500px !important;
			@media (max-width: 991px) {
				height: 300px !important;
			}
		}
	}
    .map-info {
        @media (max-width: 991px) {
            margin-top: 30px;
        }
    }
    &.cs-style-dark {
        ::v-deep {
            .button-group {
                .cs-button {
                    color: #fff !important;
                    &:hover {
                        color: #fff !important;
                    }
                }
            }
            .button-group:nth-child(even) .cs-button {
                color: #fff !important;
                &:hover {
                    color: #fff !important;
                }
            }
        }
    }
    ::v-deep {
        /* btns styles */
        .button-group {
            .button {
                max-width: 250px !important;
            }
            .cs-button {
                @include base-transition;
                background: $theme_color !important;
                color: $secondary_text_color !important;
                font-weight: 500;
                border: 1px solid $theme_color;
                min-width: 130px;
                padding: 15px !important;
                border-radius: 0px !important;
                min-width: 150px;
                display: flex;
                justify-content: center;
                &:hover {
                    border-color: $theme_color !important;
                    background: transparent !important;
                    color: $secondary_text_color !important;
                }
            }
        }
        .button-group:nth-child(even) .cs-button {
            color: $secondary_text_color !important;
            border: 1px solid $theme_color !important;
            background: transparent !important;

            &:hover {
                background: $theme_color !important;
                color: $secondary_text_color !important;
            }
        }
    }
}
</style>